import React, { Component } from 'react';
import ProductStatusView from './ProductStatusView';

export default class ProductStatusGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        let containerStyle = {display: 'flex', flexFlow: 'row wrap', maxWidth: '10cm'};
 
        var gridItems = [];
        
        var gridCounter = 0;
        for (let status of this.props.statuses) {
            let expanded = status.id === this.state.expandedStatus && status.supported && (status.url || status.description || status.supportingStatement);
            
            if (expanded) {
                gridCounter = 0;
            }
            else {
                if (gridCounter > 0 && gridCounter % 3 === 0) {
                    gridItems.push(<hr style={{flexBasis: '100%', height: 0, margin: 0, border: 0}}/>);
                }
                gridCounter++;
            }
            
            gridItems.push(<ProductStatusView productStatus={status} onClick={() => this.setState({expandedStatus: status.id})} expanded={expanded}/>);
        }
        
        return <div style={containerStyle}>
        {
            
            gridItems
        }
        </div>;
    }
}
