import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'
import { Container, Spinner, Table } from 'reactstrap';
import LoupeIcon from '@material-ui/icons/Loupe';
import ScanClient from './ScanClient';
import ProductStatusGrid from './ProductStatusGrid';
import ContentImageView from './ContentImageView';
import ReactMarkdown from 'react-markdown';

class ScanScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            result: null,
            expandedIngredient: null
        };
    }
    
    componentDidMount() {
        let component = this;
        
        component.setState({
            loading: true,
            error: null,
            result: null
        });
        
        ScanClient.getScanData(this.props.code)
        .then(function(result) {
            component.setState({
                result: result,
                loading: false
            });
        })
        .catch(function(e){
            component.setState({
                error: e,
                loading: false
            });
        });
    }

    fullBatchName(batch) {
        if (batch) {
            var fullName = batch.productionSite.name + ":" + batch.product.name;
            
            if (batch.productionDate) {
                fullName += ":" + batch.productionDate;
            }
            if (Number.isInteger(batch.number)) {
                fullName += ":" + batch.number;    
            }
            
                
            return fullName;
        }
        else {
            return '---'
        }
    }
    
    render() {
        var content;
        
        if (this.state.loading) {
            content = <div style={{display: 'flex', flex: 1, height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
                <Spinner color="dark"/>
            </div>;
        }
        else if (this.state.error) {
            content = this.state.error;
        }
        else if (this.state.result) {
            let batch = this.state.result.batch;

            let ingredientStyle = {display: "flex", marginRight: '4mm', width: "auto", paddingRight: "1mm", border: "1px solid #b7dbff", background: "#deefff", borderRadius: "1mm", alignItems: "center"};
            
            let expandedIngredientStyle = Object.assign({}, ingredientStyle, {padding: '1mm', width: '100%', flexDirection: 'column', alignItems: 'flex-start'});
            
            let inputStyle = {display: "flex", flex: 1, padding: "1mm", marginTop: '1mm', border: "1px solid #9f9", background: "#dfd", borderRadius: "1mm", alignItems: "center"};
            
            var image;
            if (batch.product.image) {
                var onClick;
                if (batch.product.brand) {
                    console.log('Setting Brand Click');
                    onClick = () => this.props.history.push("/brand/" + batch.product.brand.id);
                }
                
                image = <ContentImageView image={batch.product.image} style={{width: '100%', height: '4cm'}} onClick={onClick}/>;
            }
            
            var description;
            if (batch.product.description) {
                description = <div style={{marginTop: '4mm', marginBottom: '-5mm'}}>
                    <ReactMarkdown source={batch.product.description} />
                </div>
            }
            
            var ingredients;
            if (batch.product.ingredients && batch.product.ingredients.length > 0)
            {
                let provenanceIngredients = {};
                for (let input of batch.inputs) {
                    var ingredient = provenanceIngredients[input.type];
                    ingredient = ingredient ? ingredient : [];
                    
                    ingredient.push(input);
                    provenanceIngredients[input.type] = ingredient;
                }
                
                let provenanceIngredientItems = [];
                
                for (let type in provenanceIngredients) {
                    let inputs = provenanceIngredients[type];
                    if (this.state.expandedIngredient === type) {
                        provenanceIngredientItems.push(<div onClick={() => this.setState({expandedIngredient: null})} style={expandedIngredientStyle}>
                            {type} sourced from:
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                {    
                                    inputs.map(input => <div style={inputStyle} onClick={() => this.props.history.push("/site/" + input.site.id)}>
                                        <LoupeIcon style={{marginRight: '3mm'}}/>{input.site.name}
                                    </div>)
                                }
                                </div>
                        </div>);
                    }
                    else {
                        provenanceIngredientItems.push(<div onClick={() => this.setState({expandedIngredient: type})} style={ingredientStyle}>
                            {type}
                        </div>);
                    }
                }
                
                let otherIngredientItems = batch.product.ingredients.filter(ingredient => ingredient !== 'Wheat').map(ingredient => <div style={{display: "flex", width: "auto", border: "1px solid #fff", alignItems: "center", marginRight: '4mm'}}>
                    {ingredient}
                </div>);
                
                ingredients = [<h2>Ingredients</h2>,
                <div style={{display: 'flex', alignItems: 'center', flexFlow: 'row wrap'}}>
                    {provenanceIngredientItems}
                    {otherIngredientItems}
                    <br/>
                </div>];
            }
            
            var nutrition;
            if (batch.product.nutrients && batch.product.nutrients.length > 0)
            {
                nutrition = <Table striped size="sm">
                    <thead>
                        <tr>
                            <th>Average Quantity</th>
                            <th>per 100g</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        batch.product.nutrients.map(nutrient => <tr key={nutrient.name}>
                                <td>{nutrient.indented ? '- ' : ''}{nutrient.name}</td>
                                <td>{nutrient.quantity}</td>
                            </tr>)
                    }                        
                    </tbody>
                </Table>;
            }
            
            var allergens;
            if (batch.product.allergens && batch.product.allergens.length > 0)
            {
                allergens = [<h3>Allergens</h3>,
                "Contains: ", batch.product.allergens.join(", "),<br/>,
                "May Contain: ", batch.product.traceAllergens.join(", ")];
            }
            
            var suitability;
            var sdgs;
            
            if (batch.product.statuses)
            {
                let sortFunc = (a, b) => a.ordering > b.ordering;
                
                let statuses = batch.product.statuses.filter(s => s.category === 'status').sort(sortFunc);
                if (statuses.length > 0) {
                    suitability = [<h3>Suitability</h3>,<ProductStatusGrid statuses={statuses} />];
                }
                
                let sgdStatuses = batch.product.statuses.filter(s => s.category === 'sdg').sort(sortFunc);
                if (sgdStatuses.length > 0) {
                    sdgs = [
                        <h2>United Nations - Sustainable Development Goals</h2>,
                        <ProductStatusGrid statuses={sgdStatuses} />
                    ];
                }
            }
            
            var trivia;
            if (batch.product.trivia && batch.product.trivia.text) {
                trivia = [<h2>Trivia</h2>,
                batch.product.trivia.text];
                
                if (batch.product.trivia.url) {
                    trivia = trivia.concat([
                        <br/>,
                        <a href={batch.product.trivia.url}>Find Out More</a>
                    ]);
                }
            }
            
            content = <div style={{padding: '4mm', marginBottom: '1cm'}}>
                {image}
                
                <h1 style={{width: '100%', textAlign: 'center'}}>{batch.product.name}</h1>
                
                {description}
                
                {ingredients}
                
                <h2>Nutritional Information</h2>
                
                {nutrition}
                
                {allergens}
                
                {suitability}
                
                {sdgs}
                
                {trivia}
                
                <h2>Production Information</h2>
                Produced at {batch.productionSite.name} <br/>
                
                
            </div>;
        }
        
        return <div style={{flex: 1, display: "flex", alignItems: 'center', justifyContent: 'center'}}>
            <Container id="content">
                {content}
            </Container>
        </div>
    }
}

export default withRouter(ScanScreen);
