import React, { Component } from 'react';

export default class ContentImageView extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        let containerStyle = Object.assign({position: 'relative', width: '100%', height: '100%'}, this.props.style);

        return <div style={containerStyle} onClick={this.props.onClick}>
            <img style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'scale-down'}} src={this.props.image ? this.props.image.url : null} alt={this.props.image ? this.props.image.altText : null} />
        </div>
    }
}
