import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'
import { Container, Spinner, Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ContentImageView from './ContentImageView';
import ScanClient from './ScanClient';
import ReactMarkdown from 'react-markdown';

class BrandScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            activeIndex: 0 
        };
    }
    
    componentDidMount() {
        let component = this;
        component.setState({
            brand: null
        });
        
        ScanClient.getBrandData(this.props.id)
        .then(function(result) {
            component.setState({
                brand: result
            });
        })
        .catch(function(e){
            component.setState({
                error: ''+e
            });
        });
    }
    
    render() {
        var content;
        
        let brand = this.state.brand;
        if (this.state.error) {
            content = this.state.error;
        }
        else if (brand) {
            var images;
            var headerImage;
            
            if (brand.images && brand.images.length > 0) {
                headerImage = <ContentImageView image={brand.images[0]} style={{width: '100%', height: '4cm'}}/>;
                
                let remainingImages = brand.images.filter((image, i) => i > 0 && !(brand.description && brand.description.includes(image.url)));
                
                if (remainingImages.length > 0)
                    {
                    let carouselItems = remainingImages.map(image => <CarouselItem key={image.id} >
                        <div style={{position: 'relative', width: '100%', paddingBottom: '50%'}}>
                            <div style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <ContentImageView image={image} style={{maxWidth: '100%', maxHeight: '100%'}}/>
                            </div>
                        </div>
                    </CarouselItem>);
                    
                    let previous = () => this.setState({activeIndex: (this.state.activeIndex + remainingImages.length - 1) % remainingImages.length});
                    let next = () => this.setState({activeIndex: (this.state.activeIndex + 1) % remainingImages.length});
                    
                    images = <Carousel
                        activeIndex={this.state.activeIndex}
                        next={next}
                        previous={previous}>
                        
                        <CarouselIndicators items={remainingImages} activeIndex={this.state.activeIndex} onClickHandler={index => this.setState({activeIndex: index})}/>
                        
                        {carouselItems}
                        
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                    </Carousel>;
                }
            }
            
            var description;
            if (brand.description) {
                description = <div style={{marginTop: '4mm'}}>
                    <ReactMarkdown className="description" source={brand.description} />
                </div>
            }
            
            content = <div style={{padding: '4mm', marginBottom: '1cm'}}>
                <div style={{width: '100%', padding: '4mm', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <BackIcon style={{width: '1cm', marginRight: '1cm'}} onClick={() => this.props.history.goBack()}/>
                </div>
                
                {headerImage}
                
                {description}
                
                {images}
                
            </div>
        }
        else {
            content = <div style={{display: 'flex', flex: 1, height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
                <Spinner color="dark"/>
            </div>;
        }
            
                
        
        return <div style={{flex: 1, display: "flex", alignItems: 'center', justifyContent: 'center'}}>
            <Container id="content">
                {content}
            </Container>
        </div>;
    }
}

export default withRouter(BrandScreen);
