import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScanScreen from './ScanScreen';
import InfoScreen from './InfoScreen';
import SiteScreen from './SiteScreen';
import BrandScreen from './BrandScreen';

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path='/' exact={true} render={() => <InfoScreen/>}/>
                    <Route path='/info' exact={true} render={() => <InfoScreen/>}/>
                    <Route path='/:code' exact={true} render={({ match }) => <ScanScreen code={match.params.code}/>}/>
                    <Route path='/site/:id' exact={true} render={({ match }) => <SiteScreen id={match.params.id}/>}/>
                    <Route path='/brand/:id' exact={true} render={({ match }) => <BrandScreen id={match.params.id}/>}/>
                </Switch>
            </Router>
        );
    }
}

export default App;

