import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'
import { Container, Spinner, Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ContentImageView from './ContentImageView';
import config from './config';
import ScanClient from './ScanClient';
import ReactMarkdown from 'react-markdown';
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api"

class SiteScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            activeIndex: 0 
        };
    }
    
    componentDidMount() {
        let component = this;
        component.setState({
            site: null
        });
        
        ScanClient.getSiteData(this.props.id)
        .then(function(result) {
            component.setState({
                site: result
            });
        })
        .catch(function(e){
            component.setState({
                error: e
            });
        });
    }
    
    render() {
        var content;
        
        let site = this.state.site;
        if (this.state.error) {
            content = this.state.error;
        }
        else if (site) {
            var images;
            if (site.images && site.images.length > 0) {
                let carouselItems = site.images.map(image => <CarouselItem key={image.id} >
                    <div style={{position: 'relative', width: '100%', paddingBottom: '50%'}}>
                        <div style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <ContentImageView image={image} style={{maxWidth: '100%', maxHeight: '100%'}}/>
                        </div>
                    </div>
                </CarouselItem>);
                
                let previous = () => this.setState({activeIndex: (this.state.activeIndex + site.images.length - 1) % site.images.length});
                let next = () => this.setState({activeIndex: (this.state.activeIndex + 1) % site.images.length});
                
                images = <Carousel
                    activeIndex={this.state.activeIndex}
                    next={next}
                    previous={previous}>
                    
                    <CarouselIndicators items={site.images} activeIndex={this.state.activeIndex} onClickHandler={index => this.setState({activeIndex: index})}/>
                    
                    {carouselItems}
                    
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                </Carousel>;
            }
            
            var description;
            if (site.description) {
                description = <div style={{marginTop: '4mm'}}>
                    <ReactMarkdown source={site.description} />
                </div>
            }
            
            var map;
            if (site.location) {
                let mapCentre = {lat: site.location.latitude, lng: site.location.longitude};
                
                map = <LoadScript id="script-loader" googleMapsApiKey={config.gapi_key}>
                    <GoogleMap 
                        id='example-map'
                        mapContainerStyle={{width: '100%', paddingTop: '60%'}}
                        zoom={6}
                        options={{
                            gestureHandling: 'none',
                            disableDefaultUI: true
                        }}
                        center={mapCentre}
                        onLoad={() => console.log('Map Loaded')}>

                        <Marker position={mapCentre}/>
                    
                    </GoogleMap>
                </LoadScript>;
            }
            
            var owner;
            if (site.owner) {
                owner = <div style={{flex: 1, textAlign: 'center'}}>Farmed by {site.owner}</div>;
            }
            
            content = <div style={{padding: '4mm', marginBottom: '1cm'}}>
                <div style={{width: '100%', padding: '4mm', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <BackIcon style={{width: '1cm', marginRight: '1cm'}} onClick={() => this.props.history.goBack()}/>
                    <div style={{display: 'inline-block'}}>
                        <h1>{site.name}</h1>
                    </div>
                    <div style={{width: '2cm'}}/>
                </div>
                
                {map}
                
                {owner}
                
                {description}
                
                {images}
                
            </div>
        }
        else {
            content = <div style={{display: 'flex', flex: 1, height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
                <Spinner color="dark"/>
            </div>;
        }
            
                
        
        return <div style={{flex: 1, display: "flex", alignItems: 'center', justifyContent: 'center'}}>
            <Container id="content">
                {content}
            </Container>
        </div>;
    }
}

export default withRouter(SiteScreen);
