import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'
import { Container } from 'reactstrap';

class InfoScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div>
            <Container id="content">
                <h1>Info</h1>
            </Container>
        </div>
    }
}

export default withRouter(InfoScreen);
