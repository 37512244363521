import config from './config';

const MAX_CACHE_TIME = 600000;

export default class CachingScanClient
{
    static getScanData(code) {
        return new Promise(function(resolve, reject) {
        
            fetch(config.app_server + '/publicapi/1/scan', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    code: code
                })
            }).then(function(response){
                return response.json();
            }).then(function(result) {
                if (result.batch) {
                    let savedSites = new Set();
                    let cacheTime = Date.now();
                    for (let input of result.batch.inputs) {
                        if (!savedSites.has(input.site.id)) {
                            let site = input.site;
                            site.cacheTime = cacheTime;
                            localStorage.setItem('data_site_' + site.id, JSON.stringify(site));
                            savedSites.add(site.id);
                        }
                    }
                    
                    let brand = result.batch.product ? result.batch.product.brand : null;
                    if (brand) {
                        brand.cacheTime = cacheTime;
                        localStorage.setItem('data_brand_' + brand.id, JSON.stringify(brand));
                    }
                
                    resolve(result);
                }
                else {
                    reject("No Result Found");
                }
            })
            .catch(function(e) {
                reject("Network Error");
            });
        });
    }
    
    static getSiteData(siteId) {
        return new Promise(function(resolve, reject) {
            var siteJson = localStorage.getItem('data_site_' + siteId);
            var site;
            
            if (siteJson) {
                site = JSON.parse(siteJson);
            }
            
            if (site && (!site.cacheTime || (Date.now() - site.cacheTime > MAX_CACHE_TIME))) {
                site = null;
            }
            
            if (site) {
                resolve(site);
            }
            else {
                fetch(config.app_server + '/publicapi/1/sites/' + siteId, {
                    method: 'GET'
                }).then(function(response){
                    return response.json();
                }).then(function(result) {
                    if (result.id) {
                        result.cacheTime = Date.now();
                        localStorage.setItem('data_site_' + result.id, JSON.stringify(result));
                        
                        resolve(result);
                    }
                    else {
                        reject("Site Not Found");
                    }
                })
                .catch(function(e) {
                    reject("Network Error");
                });
            }
        });
    }    
    
    static getBrandData(brandId) {
        return new Promise(function(resolve, reject) {
            var brandJson = localStorage.getItem('data_brand_' + brandId);
            var brand;
            
            if (brandJson) {
                brand = JSON.parse(brandJson);
            }
            
            if (brand && (!brand.cacheTime || (Date.now() - brand.cacheTime > MAX_CACHE_TIME))) {
                brand = null;
            }
            
            if (brand) {
                resolve(brand);
            }
            else {
                fetch(config.app_server + '/publicapi/1/brands/' + brandId, {
                    method: 'GET'
                }).then(function(response){
                    return response.json();
                }).then(function(result) {
                    if (result.id) {
                        result.cacheTime = Date.now();
                        localStorage.setItem('data_brand_' + result.id, JSON.stringify(result));
                        
                        resolve(result);
                    }
                    else {
                        reject("Brand Not Found");
                    }
                })
                .catch(function(e) {
                    reject("Network Error");
                });
            }
        });
    }
}
