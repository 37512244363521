import React, { Component } from 'react';

export default class ProductStatusView extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        let containerStyle = {display: 'block', position: 'relative', borderRadius: '2mm'};
        
        let subcontainerStyle = {display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: '2mm'};
        
        var expandedContent;
        if (this.props.expanded) {
            containerStyle.width = '100%';
            containerStyle.border = '1px solid #999';
            
            var description;
            var supportingStatement;
            var urlButton;
            
            if (this.props.productStatus.description) {
                description = <div style={{paddingTop: '5mm'}}>
                    {this.props.productStatus.description}
                </div>;
            }
            if (this.props.productStatus.supportingStatement) {
                supportingStatement = <div style={{display: 'flex', paddingTop: '5mm', flexDirection: 'column'}}>
                    <span style={{fontSize: '8pt', color: '#555', textAlign: 'center', marginBottom: '1mm'}}>SUPPORTING STATEMENT</span>
                    {this.props.productStatus.supportingStatement}
                </div>;
            }
            if (this.props.productStatus.url) {
                urlButton = <div style={{paddingTop: '5mm', textAlign: 'center'}}>
                    <a href={this.props.productStatus.url}>More Info</a>
                </div>;
            }
            
            expandedContent = <div style={{padding: '0 5mm', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <span style={{fontSize: '8pt', textAlign: 'center', overflow: 'hidden', width: '100%'}}>{this.props.productStatus.name}</span>
                
                {description}
                {supportingStatement}
                {urlButton}
            </div>;
        }
        else {
            containerStyle.width = '33%';
            containerStyle.height = '33%';
        }
        
        var fadeView;
        if (!this.props.productStatus.supported) {
            fadeView = <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#fff9', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <svg viewBox='0 0 100 100'>
                    <line x1="0" x2="100" y1="100" y2="0" stroke="white" stroke-width="6" stroke-linecap="butt"/>
                </svg>
            </div>;
        }
        
        var imageView;
        if (this.props.productStatus.icon) {
            let imageStyle = {width: '100%', maxHeight: '3cm', maxWidth: '3cm', borderRadius: '2mm'};
            imageView = <img style={imageStyle} src={this.props.productStatus.icon.url} alt={this.props.productStatus.name} />;
        }
        
        
        return <div style={containerStyle} onClick={this.props.onClick}>
                <div style={subcontainerStyle}>
                    {imageView}
                    {expandedContent}
                </div>
                {fadeView}
        </div>;
    }
}
